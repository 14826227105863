import { Field, RadioGroup } from '@lucidhq/lucidium';
import { useTranslation } from 'react-i18next';

export function GenderField() {
  const { t } = useTranslation();

  return (
    <Field
      label={t('Panelist.Gender')}
      name="gender"
      required
      render={(fieldProps) => (
        <RadioGroup
          {...fieldProps}
          isInline
          options={[
            {
              label: t('Panelist.Gender_Enum.Male'),
              value: '1',
            },
            {
              label: t('Panelist.Gender_Enum.Female'),
              value: '2',
            },
          ]}
        />
      )}
    />
  );
}
