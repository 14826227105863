import { Field } from '@lucidhq/lucidium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePanelConfig } from '@/hooks';

const isTest = import.meta.env.MODE === 'test';

export function DoBField() {
  const { t } = useTranslation();
  const { minAgeRequirement } = usePanelConfig();

  const { minAgeRequirementDate, maxAgeRequirementDate } = useMemo(() => {
    const Today = new Date();
    const maxAgeRequirementDate = new Date();
    maxAgeRequirementDate.setFullYear(
      Today.getFullYear() - minAgeRequirement || 16
    );
    const minAgeRequirementDate = new Date();
    minAgeRequirementDate.setFullYear(Today.getFullYear() - 103);

    return {
      minAgeRequirementDate: minAgeRequirementDate.toISOString().split('T')[0],
      maxAgeRequirementDate: maxAgeRequirementDate.toISOString().split('T')[0],
    };
  }, [minAgeRequirement]);

  return (
    <Field
      label={t('Panelist.Date_Of_Birth')}
      name="dob"
      data-testid="signup-form-dob"
      required
      type={isTest ? 'string' : 'date'}
      min={minAgeRequirementDate}
      max={maxAgeRequirementDate}
    />
  );
}
