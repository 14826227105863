import { t } from 'i18next';

import { useAuth, usePanelConfig } from '@/hooks';
import { isUserValid } from '@/lib';

import { MenuOptionGenerator } from './MenuOptionGenerator';

export function MenuOptionReferral({ onClick }: { onClick: () => void }) {
  const { userRole } = useAuth();
  const { showReferral } = usePanelConfig();

  if (!showReferral || !isUserValid(userRole)) return null;

  return (
    <MenuOptionGenerator
      title={t('Referral.Title')}
      path={'referral'}
      onClick={onClick}
    />
  );
}
