import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { Role, Roles, TokenResponse } from '@/lib/models';
import { request } from '@/lib/requestHelpers';

import { useLocalStorage } from './useLocalStorage';

const MINTOREFRESH = 12;

const AuthContext = createContext<{
  token: string;
  refreshToken: string;
  panelistId: string;
  userRole: Role;
  loading: boolean;
  userName: string;
  login: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    token: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refresh: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    role: Role,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    panelistId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userName: string
  ) => void;
  logout: () => void;
}>({
  token: '',
  refreshToken: '',
  panelistId: '',
  userName: '',
  userRole: Roles.Registering,
  loading: false,
  login: () => undefined,
  logout: () => undefined,
});
const Provider = AuthContext.Provider;

export function useAuth() {
  return useContext(AuthContext);
}

async function refreshToken(refresh_token: string): Promise<TokenResponse> {
  const response = await request({
    url: 'token',
    method: 'POST',
    body: [
      ['refresh_token', refresh_token],
      ['grant_type', 'refresh_token'],
      ['client_id', 'PPR_Web'],
    ],
    isFormData: true,
  });

  return response;
}

type State = {
  token: string;
  panelistId: string;
  refreshToken: string;
  userRole: Role;
  loading: boolean;
  needRefresh: boolean;
  userName: string;
};

enum StateActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  LOADING = 'LOADING',
  REFRESH = 'REFRESH',
}

type StateAction =
  | {
      type: StateActions.LOGIN;
      panelistId: string;
      userName: string;
      token: string;
      refreshToken: string;
      userRole: Role;
    }
  | { type: StateActions.LOGOUT }
  | { type: StateActions.LOADING }
  | { type: StateActions.REFRESH };

const initialAuthState: State = {
  token: '',
  panelistId: '',
  refreshToken: '',
  userName: '',
  userRole: Roles.Registering,
  loading: true,
  needRefresh: false,
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useLocalStorage<State>(
    'authState',
    initialAuthState
  );

  // useReducer to migrate useStates
  const [state, dispatch] = useReducer((state: State, action: StateAction) => {
    let newState = state;

    switch (action.type) {
      case StateActions.LOGIN:
        newState = {
          ...state,
          loading: false,
          needRefresh: false,
          token: action.token,
          refreshToken: action.refreshToken,
          userRole: action.userRole,
          panelistId: action.panelistId,
          userName: action.userName || '',
        };
        break;
      case StateActions.LOGOUT:
        newState = {
          ...initialAuthState,
        };
        break;
      case StateActions.LOADING:
        newState = {
          ...state,
          loading: true,
        };
        break;
      case StateActions.REFRESH:
        newState = {
          ...state,
          needRefresh: true,
        };
        break;
    }

    setAuthState(newState);

    return newState;
  }, authState);

  function login(
    token: string,
    refreshToken: string,
    userRole: Role,
    panelistId: string,
    userName: string
  ) {
    dispatch({
      type: StateActions.LOGIN,
      token,
      refreshToken,
      userRole,
      panelistId,
      userName,
    });
  }

  function logout() {
    dispatch({ type: StateActions.LOGOUT });
  }

  useEffect(() => {
    if (state.refreshToken) {
      dispatch({ type: StateActions.REFRESH });
    }
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => {
        dispatch({ type: StateActions.REFRESH });
      },
      MINTOREFRESH * 60 * 1000
    );

    return () => clearInterval(interval);
  }, [state.refreshToken]);

  useEffect(() => {
    async function fetchData() {
      if (!state.refreshToken) {
        logout();
        return;
      }
      if (!state.needRefresh || !state.token) return;

      try {
        dispatch({ type: StateActions.LOADING });
        const data = await refreshToken(state.refreshToken);
        if (data.access_token) {
          login(
            data.access_token,
            state.refreshToken,
            data.panelist_role,
            data.client_id,
            authState.userName
          );
        } else {
          logout();
        }
      } catch (e: any) {
        logout();
      }
    }

    fetchData();
    // Only run when needRefresh changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.needRefresh]);

  return (
    <Provider
      value={{
        refreshToken: state.refreshToken,
        token: state.token,
        userRole: state.userRole,
        loading: state.loading,
        panelistId: state.panelistId,
        userName: state.userName,
        login,
        logout,
      }}
    >
      {children}
    </Provider>
  );
}
