import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { panelStorage } from './lib/panelStorage';

export const languages = [
  'ar',
  'bg',
  'bs',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fil',
  'fr',
  'he',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'mk',
  'ms',
  'nl',
  'no',
  'pl',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
];

function getLang() {
  let browserLocale;
  if (navigator.languages != undefined) browserLocale = navigator.languages[0];
  else browserLocale = navigator.language;
  if (browserLocale.includes('-')) browserLocale = browserLocale.split('-')[0];
  return browserLocale;
}

const item = panelStorage.getItem('language');
const browserLocale = getLang();
const lng = item ? JSON.parse(item) : browserLocale || 'en';

// Avoid double initialization on tests
!i18n.isInitialized &&
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      // Language to use (overrides language detection)
      lng,
      // Language to use if translations in user language are not available.
      fallbackLng: 'en',
      interpolation: {
        // Escape passed in values to avoid xss injection
        escapeValue: false,
      },
      backend: {
        // path where resources get loaded from, or a function returning a path: function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: '/locales/{{lng}}.json',
      },
    });

export default i18n;
