// In incognito mode, localStorage is not available.
// So we use a custom storage instead.
function panelStorageGenerator() {
  try {
    return localStorage;
  } catch (err) {
    const storage = new Map<string, string>();
    return {
      clear() {
        storage.clear();
      },
      getItem(key: string) {
        return storage.get(key);
      },
      key(index: number) {
        return (storage.values as any)[index];
      },
      removeItem(key: string) {
        storage.delete(key);
      },
      setItem(key: string, data: string) {
        storage.set(key, data);
      },
    };
  }
}

export const panelStorage = panelStorageGenerator();
