import { useMemo } from 'react';

import { usePanelConfig } from './usePanelConfig';
import { Color } from '@/lib';

const thresholdColorBrightness = 140;

export function useTextButtonColor(): string {
  const { panelColorCode } = usePanelConfig();

  const color = useMemo(() => {
    const color = Color.FromHex(panelColorCode);
    return color.perceivedBrightness() < thresholdColorBrightness
      ? 'white'
      : 'black';
  }, [panelColorCode]);

  return color;
}
