import { useMemo } from 'react';

import { useAuth, usePanelConfig, useSWRAuth } from '@/hooks';
import { endpoints, isUserMinor } from '@/lib';
import {
  BasicProfileResponse,
  ConsentOptIn,
  Gender,
  OptIn,
  PanelConsent,
} from '@/lib/models';

function calculateAgeFromDate(birthday: string) {
  const ageDifMs = Date.now() - new Date(birthday).getUTCMilliseconds();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const sortBy = (key: string) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

const Today = new Date();
const NotToday = new Date();
NotToday.setFullYear(Today.getFullYear() - 18);

export function usePartialUpdateModal() {
  const { userRole } = useAuth();
  const { profilingSettings, minAgeRequirement } = usePanelConfig();
  const { showPostalCode, postalCodeRegex } = profilingSettings;
  const { data: basicProfile, mutate: mutateProfile } =
    useSWRAuth<BasicProfileResponse>({
      url: endpoints.getBasicProfile,
      errorMessage: 'unable to get basic profile',
    });
  const { data: consents, mutate: mutateConsents } = useSWRAuth<PanelConsent[]>(
    {
      url: endpoints.getPanelConsent,
      errorMessage: 'unable to get panel consents',
    }
  );

  const consentsFiltered = useMemo(() => {
    const missing = consents
      ?.filter(
        (c) => c.consentContent.mandatory && c.panelistOptIn !== OptIn.Yes
      )
      .map((c) => ({
        sortOrder: c.consentContent.sortOrder,
        consentId: c.consentContent.consentId,
        consentContentId: c.consentContent.consentContentId,
        termsOfUse: c.consentContent.termsOfUse,
        description: c.consentContent.description,
        optIn: false,
        mandatory: c.consentContent.mandatory,
        displayGroup:
          c.consentContent.displayGroup == null
            ? 0
            : c.consentContent.displayGroup,
      }));

    return missing?.concat().sort(sortBy('sortOrder'));
  }, [consents]);

  function updateConsents(consentUpdates: ConsentOptIn[]) {
    const newConsents = (consents || []).map((consent) => {
      const consentUpdate = consentUpdates.find(
        (o) => o.consentId === consent.consentContent.consentId
      );

      return {
        ...consent,
        panelistOptIn: consentUpdate
          ? consentUpdate.optIn
            ? 1
            : 0
          : consent.panelistOptIn,
      };
    });

    // no need for revalidate
    mutateConsents(newConsents, false);
  }

  function updateProfile(
    profileUpdates: { dimensionName: string; value: any }[]
  ) {
    if (!basicProfile) return;

    const newProfile = { ...basicProfile };

    const dob = profileUpdates.find((p) => p.dimensionName === 'DateOfBirth')
      ?.value;
    if (dob) {
      newProfile.dateOfBirth = dob;
    }
    const gender = profileUpdates.find((p) => p.dimensionName === 'Gender')
      ?.value;
    if (gender) {
      newProfile.gender = gender;
    }
    const postal = profileUpdates.find((p) => p.dimensionName === 'PostalCode')
      ?.value;
    if (postal) {
      newProfile.postalCode = postal;
    }

    mutateProfile(newProfile, false);
  }

  const postalCodeNeedsUpdate =
    showPostalCode &&
    (!basicProfile?.postalCode ||
      !new RegExp(postalCodeRegex).test(basicProfile?.postalCode));

  const genderNeedsUpdate =
    basicProfile?.gender === null ||
    // eslint-disable-next-line
    // @ts-ignore-next-line
    (basicProfile?.gender != Gender.Male &&
      // eslint-disable-next-line
      // @ts-ignore-next-line
      basicProfile?.gender != Gender.Female);

  const birthDateNeedsUpdate =
    !basicProfile?.dateOfBirth ||
    calculateAgeFromDate(basicProfile?.dateOfBirth) <= minAgeRequirement;

  return {
    basicProfile,
    isMinor: isUserMinor(userRole),
    consentsFiltered,
    needsUpdate: {
      postalCode: postalCodeNeedsUpdate,
      gender: genderNeedsUpdate,
      birthDate: birthDateNeedsUpdate,
      consents: (consentsFiltered || []).length > 0,
    },
    updateConsents,
    updateProfile,
  };
}
