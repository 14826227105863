import { APIURL } from './env';

export function baseFetch(url: string) {
  return fetch(`${APIURL}/${url}`).then((res) => res.json());
}

export function tokenFormater(token: string | unknown, panelGUID: string): any {
  return typeof token === 'string'
    ? {
        type: 'ReCaptcha',
        content: token,
        panelGuid: panelGUID,
      }
    : token;
}

const errorDictonary = {
  'Could not register from this geographical area.': 'Registration.GeoBlock',
  'Email already registered for this panel': 'Email.Validation.Already_Exists',
  'PostalCode is invalid': 'Panelist.Server.Postal_Code_Invalid',
  'DateOfBirth is invalid': 'Panelist.Server.Year_Of_Birth_Invalid',
  'YearOfBirth is invalid': 'Panelist.Server.Year_Of_Birth_Invalid',
  'PhoneNumber is invalid': 'Panelist.Validation.Phone_Number_Invalid',
  'Registration is not allowed in your location':
    'Panelist.Server.Location_Invalid',
  'Invalid request': 'Panelist.Server.Other_Error',
  'The user name or password is incorrect': 'Auth.Wrong_Username_Or_Password',
  'Bad status: BadEmail': 'Email.Validation.Bad_Email',
  'Password is incorrect': 'Password.Validation.Wrong',
};

export function translateErrors(errors: any[] | string) {
  if (Array.isArray(errors)) {
    const newErrors = (
      errors as unknown as [{ message: keyof typeof errorDictonary }]
    ).map((m) => errorDictonary[m.message] || m.message || m);

    return newErrors;
  } else {
    return (errorDictonary as any)[errors] || 'Errors.Server';
  }
}

export async function request({
  url,
  method,
  body,
  token,
  userName,
  isFormData,
  is400,
}: {
  url: string;
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: any;
  token?: string;
  userName?: string;
  isFormData?: boolean;
  is400?: boolean;
}) {
  const params: any = {
    method,
    headers: {
      accept: 'application/json',
    },
  };

  try {
    if (AbortSignal && AbortSignal.timeout) {
      params.signal = AbortSignal.timeout(5000);
    }
  } catch (error) {}

  let result;
  if (isFormData) {
    if (!Array.isArray(body)) {
      throw new Error('body should be of Array Type');
    }
    const formData: string[] = body.map(
      (c: any) => encodeURIComponent(c[0]) + '=' + encodeURIComponent(c[1])
    );
    result = formData.join('&');
    params.headers = {
      ...params.headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  } else {
    result = JSON.stringify(body);
    params.headers = {
      ...params.headers,
      'Content-Type': 'application/json',
    };
  }

  params.body = result;

  token &&
    (params.headers = {
      ...params.headers,
      Authorization: `Bearer ${token}`,
    });

  userName &&
    (params.headers = {
      ...params.headers,
      'X-UserName': userName,
    });

  const response = await fetch(`${APIURL}/${url}`, params);

  // Check the response is valid but empty (E.g.: PostForgotPassword)
  const tData = await response.clone().text();
  if (!tData.length) {
    if (response.status >= 200 && response.status < 300) {
      return { ...response, message: 'success' } as any;
    } else if (response.status >= 400 && response.status < 500) {
      return { ...response, message: 'badRequest' } as any;
    } else {
      throw response;
    }
  } else {
    const data = await response.json();
    if (
      response.ok ||
      (response.status > 399 && response.status < 500 && is400)
    ) {
      return data;
    } else {
      throw data;
    }
  }
}
