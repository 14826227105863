import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { queryParamKeys } from '@/lib';

export function useIsIframe() {
  const [searchParams] = useSearchParams();
  const isIframe = useMemo(
    () =>
      Array.from(searchParams.entries())
        .map(([k, v]) => ({ key: k.toLowerCase(), v }))
        .find(({ key }) => key === queryParamKeys.isIframe)?.v === 'true',
    [searchParams]
  );

  return isIframe;
}
