export type Role =
  | 'Registering'
  | 'User'
  | 'Minor'
  | 'PasswordReset'
  | 'PasswordSet';

export const Roles: { [key: string]: Role } = {
  Registering: 'Registering',
  User: 'User',
  Minor: 'Minor',
  PasswordReset: 'PasswordReset',
  PasswordSet: 'PasswordSet',
};

export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  client_id: string;
  email: string;
  panel: string;
  panelist_id: string;
  individual_id: string;
  panelist_role: Role;
  '.issued': string;
  '.expires': string;
}

export interface OportunityResponse {
  id: number;
  incentive: string;
  lengthOfInterview: number;
  startUrl: string;
  respondentGuid?: any;
}

export type PanelConfig = {
  panelGUID: string;
  panelName: string;
  panelId: number;
  panelLogoUrl: string;
  panelLanguageCode: string;
  twoLetterIsoCountryCode: string;
  panelColorCode: string;
  profilingSettings: ProfilingSettings;
  isRegistrationEnabled: boolean;
  showRewards: boolean;
  showReferral: boolean;
  minAgeRequirement: number;
  isCaptchaEnabled: boolean;
  isLoginEnabled: boolean;
};

export type ProfilingSettings = {
  showPostalCode: boolean;
  showDaysBetweenMailOuts: boolean;
  isMobilePhoneFieldEnabled: boolean;
  isMobilePhoneFieldRequired: boolean;
  postalCodeRegex: string;
};

export interface Consent {
  consentId: number;
  consentContentId: number;
  consentName: string;
  title: string;
  isoLanguage: string;
  sortOrder: number;
  termsOfUse: string;
  description: string;
  mandatory: boolean;
  displayGroup?: any;
}

export interface PanelConsent {
  consentContent: Consent;
  panelistOptIn: number;
}

export interface ConsentOptIn {
  consentId: number;
  consentContentId: number;
  optIn: boolean;
}

export interface BasicProfileResponse {
  address: string;
  cellPhoneNumber: string;
  dateOfBirth: string;
  daysBetweenMailOuts: number;
  emailAddress: string;
  firstName: string;
  gender: string;
  lastName: string;
  postalCode: string;
  yearOfBirth: number;
}

export interface Category {
  id: number;
  text: string;
  icon: string;
  numberOfSensitiveQuestions: number;
  numberOfQuestionsShownInPages: number;

  // added by useCategoryList
  awaitingQuestionCount: number;
}

export interface CategoryMetaData {
  categoryId: number;
  staleQuestions: number;
  staleSensitiveQuestions: number;
  unansweredQuestions: number;
  unansweredSensitiveQuestions: number;
}

export interface History {
  nextTransactionId: number;
  transactions: HistoryTransaction[];
}

export interface BaseTransaction {
  id: number;
  created: Date;
  amount: Amount;
}

export interface BaseTransactionExtended extends BaseTransaction {
  usedPaymentMethod?: string;
  rewardCodeType?: string;
  rewardCode?: string;
  status?: ITransactionStatus;
}

export interface Transaction extends BaseTransaction {
  hasRespondent: boolean;
}

export interface Amount {
  points: number;
  currencyAmount: number;
}

export interface PanelQuestionInVariable {
  id: number;
  isSelected: boolean;
  text: string;
}

export interface PanelQuestionInCategory {
  id: number;
  isSensitiveTopic: boolean;
  isStale: boolean;
  questionType: number;
  text: string;
  variables: PanelQuestionInVariable[];
}

export interface ReferralResponse {
  currencyCode: string;
  rewardAmount: number;
  url: string;
}

export interface HistoryTransaction extends BaseTransaction {
  transactionType: number;
  status: ITransactionStatus;
}

export enum ITransactionStatus {
  Pending = 0,
  UnderProcess = 1,
  Success = 2,
  Failed = 3,
}

export interface PaymentMethod {
  panelistId: number;
  redeemAmount: Amount;
  redeemAllowed: boolean;
  name: string;
  paymentMethodId: number;
  panelId: number;
  minLimit: Amount;
  maxLimit: Amount;
  description: string;
  readMore: string;
  imageUrl: string;
  color: string;
  isEnabled: boolean;
  isCharity: boolean;
}

export interface PointsTransaction {
  id: number;
  amount: Amount;
  status: ITransactionStatus;
  description: string;
  created: Date;
  usedPaymentMethod: string;
  paymentMethodId: number;
  panelistId: number;
  panelId: number;
  paymentMethodImageUrl: string;
  rewardCode: string;
  rewardCodeType: RewardCodeType;
}

export type RewardCodeType = 'Amazon' | 'GCode' | 'Paypal' | 'Unknown';

export interface RecaptchaTokenResponse {
  type: string;
  content: string;
  panelGuid: string;
}

export interface RedeemRestriction {
  reason: RedeemRestrictionReason;
  expires?: Date;
}

export enum RedeemRestrictionReason {
  EmailChanged = 1,
  Unspecified = 2,
}

export enum OptIn {
  NoAnswer = -1,
  No = 0,
  Yes = 1,
}

export enum Gender {
  Unspecified = 0,
  Male = 1,
  Female = 2,
}

export type PostResponse<T> = {
  message?: string;
  error_description?: string;
} & T;

export interface SessionStartAPIResponse extends Response {
  sessionId: string;
}

export interface SessionStatusAPIResponse extends SessionStartAPIResponse {
  status: SessionStatusEnum;
  validationFailureReason?: ValidationFailureReasonEnum;
}

export enum SessionStatusEnum {
  Unknown,
  ValidationInProgress,
  ValidationSucceeded,
  ValidationFailed,
  Succeeded,
  Failed,
  TimedOut,
}

export enum ValidationFailureReasonEnum {
  Unknown,
  NotUniquePhoneNumber,
  InvalidPhoneNumber,
}

export type CodeAPIResponse = {
  isValid: boolean;
  failureReason: ResendVerificationCodeFailureReasonEnum;
};

export type ResendCodeAPIResponse = {
  isSuccess: boolean;
  failureReason: ResendVerificationCodeFailureReasonEnum;
};

export enum ResendVerificationCodeFailureReasonEnum {
  Unknown,
  ResendUnavailable,
}

export const SENSITIVE_CONSENT_ID = 7;
