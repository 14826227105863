import { trackError } from '@lucidhq/ui-analytics';
import { useEffect, useState } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { APIURL, endpoints, timeout } from '@/lib';
import { Roles } from '@/lib/models';

import { useAuth } from './useAuth';

const WAIT_RETRY_REQUEST = 1000;
const MAX_RETRY_ATTEMPS_REQUEST = 2;
const USER_ROLE_TO_AVOID_RETRY = Roles.Registering;
const KEYS_TO_AVOID_RETRY = [endpoints.getReferralInfo];

export function concatHistoryData(useSWRNext: any) {
  return (key: any, fetcher: any, config: SWRConfiguration) => {
    // TOOD: useState too overkill, useRef?
    const [data, setData] = useState<
      undefined | { transactions: any[]; nextTransactionId: number }
    >(undefined);

    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      if (
        !swr.isLoading &&
        swr.data?.transactions?.length &&
        swr.data?.nextTransactionId !== data?.nextTransactionId
      ) {
        setData({
          transactions: [
            ...(data?.transactions || []),
            ...swr.data.transactions,
          ],
          nextTransactionId: swr.data.nextTransactionId,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(swr.data)]);

    return { ...swr, data };
  };
}

interface useSWRAuthProps {
  url?: string;
  formatter?: (data: any) => any;
  config?: SWRConfiguration;
  errorMessage?: string;
}

export function useSWRAuth<T>({
  url,
  formatter,
  config,
  errorMessage,
}: useSWRAuthProps) {
  const { token, logout, userName, userRole, loading: loadingAuth } = useAuth();

  async function fetcher([url, token]: [string, string]) {
    const options = {
      headers: {},
    };

    token &&
      (options.headers = {
        Authorization: `Bearer ${token}`,
      } as any);

    options.headers = {
      ...options.headers,
      'X-Username': userName,
    } as any;

    loadingAuth && (await timeout(WAIT_RETRY_REQUEST));
    const response = await fetch(`${APIURL}/${url}`, options);

    if (response.ok) {
      // Check if the response is valid but empty
      const tData = await response.clone().text();
      if (response.status >= 200 && response.status <= 204 && !tData.length) {
        return { ...response, message: 'success' } as any;
      }

      const data = await response.json();

      return formatter ? formatter(data) : data;
    } else {
      // TODO: review as requestHelpers
      throw response;
    }
  }

  function onError(error: any, key: string) {
    trackError(errorMessage || key, error);
  }

  function onErrorRetry(
    error: { status: string },
    key: string,
    config: any,
    revalidate: any,
    { retryCount }: { retryCount: number }
  ) {
    // Check auth status
    if (error.status === '401' && userRole !== USER_ROLE_TO_AVOID_RETRY) {
      if (!loadingAuth) {
        logout();
      } else {
        setTimeout(
          () => revalidate({ retryCount }),
          WAIT_RETRY_REQUEST * retryCount
        );
      }
    } else {
      // Avoid Referral as some panels dont have it active
      if (KEYS_TO_AVOID_RETRY.includes(key)) return;

      // Only retry up to 2 times.
      if (retryCount >= MAX_RETRY_ATTEMPS_REQUEST) return;

      revalidate({ retryCount });
    }
  }

  return useSWR<T>(!url ? null : [url, token], fetcher, {
    onError,
    onErrorRetry,
    ...config,
  });
}
