import { usePanelConfig } from '@/hooks';

export function ProfileIcon() {
  const { panelColorCode } = usePanelConfig();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1284_4429"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={panelColorCode} />
      </mask>
      <g mask="url(#mask0_1284_4429)">
        <circle cx="20" cy="20" r="20" fill={panelColorCode} />
        <path
          d="M27.4197 23.3307L27.4208 23.3318C28.3808 24.3174 29.1464 25.4869 29.6742 26.7755L29.6745 26.776C30.1617 27.9613 30.4358 29.2125 30.4917 30.5H29.0106H10.9894H9.50802C9.56245 29.2198 9.83915 27.9595 10.3254 26.7794L10.3256 26.7789C10.8546 25.4906 11.6202 24.3211 12.5795 23.3345L12.5804 23.3336C13.5448 22.3368 14.6667 21.5577 15.9164 21.0143L15.9232 21.0114C15.9269 21.0099 15.9316 21.008 15.9365 21.006C15.9477 21.0013 15.9633 20.9944 15.9812 20.9852L16.6926 20.6186L16.0511 20.1399C14.3526 18.8727 13.2447 16.81 13.2447 14.4774C13.2447 10.6086 16.2823 7.5 20 7.5C23.7177 7.5 26.7553 10.6086 26.7553 14.4774C26.7553 16.8101 25.6474 18.8725 23.9493 20.1366L23.3065 20.6151L24.0188 20.9821C24.0367 20.9914 24.0523 20.9982 24.0635 21.0029C24.0675 21.0046 24.0713 21.0062 24.0746 21.0075C24.0754 21.0078 24.0763 21.0081 24.077 21.0084L24.0831 21.0111C25.3292 21.5546 26.4624 22.342 27.4197 23.3307ZM30.766 30.5H30.7659H30.766ZM27.7789 22.9829C28.7842 24.015 29.585 25.2387 30.1369 26.5859L27.7789 22.9829Z"
          fill="white"
          stroke={panelColorCode}
        />
        <circle cx="20" cy="20" r="19.5" stroke={panelColorCode} />
      </g>
    </svg>
  );
}
