import { sprintf } from 'printj';

export const endpoints = {
  getBasicProfile: 'Profiling/BasicProfiling',
  getPanelConfig: (panelGUID: string) => `WebPanelist/PanelAssets/${panelGUID}`,
  getLanguageLinks: (panelGUID: string) => `Panel/${panelGUID}/LanguageLinks`,
  getOpportunities: 'opportunity/opportunities',
  getBalance: 'reward/balance',
  getPanelConsent: 'Consent/PanelistConsents',
  getPanelCategories: 'Profiling/PanelCategories',
  getPanelQuestionsByCategoryId: (categoryId: number) =>
    `Profiling/PanelCategories/${categoryId}/PanelQuestions`,
  getReferralInfo: 'Referral',
  getReferralHistory: (id: number) => `referral/transactions/${id}`,
  getRewardsHistory: (id: number) =>
    `Reward/PanelPaymentTransactions?fromId=${id}`,
  getPaymentMethods: 'Reward/PaymentMethodSummaries',
  getRewardTransaction: (id: string | undefined) =>
    `Reward/PanelPaymentTransaction/${id}`,
  getPanelistCategoryMetaData: 'Profiling/PanelistCategoryMetaData',
  getIsAccountValidated: 'PanelistAccount/IsAccountValidated',
  getOpportunitiesHistory: (id: number) => `opportunity/transactions/${id}`,
  getConsents: (panelGUID: string) =>
    `Consent/ConsentContents/panel/${panelGUID}`,
  getRedeemRestriction: 'Reward/RedeemRestriction',
  logout: 'PanelistAccount/LogOut',
  resetPassword: 'ResetPassword/UpdatePassword',
  verifyRegistration: (token: string | null) =>
    `PanelistAccount/VerifyRegistration?token=${sprintf('%s', token)}`,
  verifyRegistrationV2: `PanelistAccount/V2/VerifyRegistration`,
  unsubscribeWithToken: (token: string | null) =>
    `PanelistAccount/Unsubscribe?token=${sprintf('%s', token)}`,
  acceptOpportunity: (id: number) => `opportunity/${id}/accept`,
  declineOpportunity: (respondentGuid: string) =>
    `opportunity/${respondentGuid}/decline`,
  profilePartialUpdate: 'Profiling/PartialUpdateBasicProfiling',
  createPanelistConsents: 'Consent/CreatePanelistConsentOptIns',
  signIn: 'token',
  signWithAuthToken: 'token',
  sendWelcomeEmail: 'PanelistAccount/SendWelcomeEmail',
  createPanelist: 'PanelistAccount/Create',
  sendForgotPasswordEmail: 'ResetPassword/SendForgotPasswordEmail',
  updateBasicProfiling: 'Profiling/UpdateBasicProfiling',
  redeem: 'reward/redeem',
  updateEmailAddress: 'WebPanelist/UpdateEmailAddress',
  updatePassword: 'WebPanelist/UpdatePassword',
  unsubscribe: 'PanelistAccount/Unsubscribe',
  answerPanelQuestion: 'Profiling/AnswerPanelQuestion',
  grantAntiBotToken: 'WebPanelist/GrantAntiBotToken',
  startVerificationSession: 'verification',
  resendVerificationToken: (sessionId: string) =>
    `verification/${sessionId}/resend`,
  sendVerificationSMSCode: (sessionId: string) =>
    `verification/${sessionId}/sms`,
  getSessionStatusAsync: (sessionId: string) =>
    `verification/${sessionId}/status`,
};
