import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenuItem,
  Flex,
  IconDropdown,
  useBreakpoint,
} from '@lucidhq/lucidium';

import { useAuth } from '@/hooks';

import { BurgerIcon, ProfileIcon } from './Icons';
import { TabGenerator } from './TabGenerator';
import { TabReferral } from './TabReferral';
import { MenuOptionGenerator } from './MenuOptionGenerator';
import { MenuOptionReferral } from './MenuOptionReferral';

const linkStyles = {
  textDecoration: 'none',
  border: 'none',
  px: 'xs',
  py: 'sm',
  ':not([disabled]):hover': {
    textDecoration: 'none',
    bg: 'gray300',
  },
} as any;

export function Menu() {
  const isMobile = !useBreakpoint('600px');
  const { logout } = useAuth();
  const [isOpen, onToggle] = useState(false);
  const { t } = useTranslation();

  const listTabs = [
    {
      title: 'Profile.Title',
      path: 'profile',
    },
    {
      title: 'Survey.Title',
      path: 'surveys',
    },
    {
      title: 'Rewards.Title',
      path: 'rewards',
    },
  ];

  return (
    <>
      {!isMobile && (
        <Flex lss={{ flexDirection: 'row' }}>
          {listTabs.map((lt) => (
            <TabGenerator key={`menu-tab-${lt.title}`} {...lt} />
          ))}
          <TabReferral />
        </Flex>
      )}
      <IconDropdown
        id="application-dropdown-menu"
        icon={isMobile ? <BurgerIcon /> : <ProfileIcon />}
        isOpen={isOpen}
        onToggle={onToggle}
        ariaLabel="Application Menu"
        buttonProps={
          {
            lss: {
              border: 'initial',
              width: 'initial',
              height: 'initial',
              ':focus': {
                outline: 'none',
              },
              ':not([disabled]):hover': {},
              ':active': {},
            } as any,
            'data-testid': 'application-dropdown-menu-button',
          } as any
        }
        contentProps={{
          position: 'left',
          lss: {
            top: isMobile ? '2.5rem' : '3.438rem',
            display: 'flex',
            flexDirection: 'column',
            width: 'initial',
            height: 'initial',
            mobile: {
              right: 0,
            },
            tablet: {
              right: '15%',
            },
            '@media (min-width:50em)': {
              right: '40%',
            },
            smallscreen: {
              right: '60%',
            },
            '@media (min-width:100em)': {
              right: '80%',
            },
          } as any,
        }}
      >
        {isMobile && (
          <>
            {listTabs.map((lt, i) => (
              <MenuOptionGenerator
                key={`menu-option-${i}`}
                {...lt}
                onClick={() => onToggle(false)}
              />
            ))}
            <MenuOptionReferral onClick={() => onToggle(false)} />
            <Flex
              lss={{
                paddingTop: 10,
                marginTop: 10,
                width: '80%',
                alignSelf: 'center',
                borderTop: '1px solid black',
              }}
            />
          </>
        )}
        <MenuOptionGenerator
          title={t('Account.Privacy_Settings.Title')}
          path={'privacysettings'}
          onClick={() => onToggle(false)}
        />
        <Flex
          onClick={logout}
          lss={{
            ...linkStyles,
            cursor: 'pointer',
          }}
        >
          <DropdownMenuItem
            text={t('Auth.Log_out')}
            textLss={{
              ':hover': {
                textDecoration: 'none',
              },
            }}
          />
        </Flex>
      </IconDropdown>
    </>
  );
}
