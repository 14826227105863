import purify from 'dompurify';

purify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export function DomPurified({ html }: { html: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: purify.sanitize(html),
      }}
    />
  );
}
