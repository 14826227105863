const FONT_FAMILY = 'Nunito Sans';

export const defaultThemeProps = {
  fontFamilies: {
    default: FONT_FAMILY,
    heading: FONT_FAMILY,
    mono: FONT_FAMILY,
  },
  typography: {
    caption: {
      fontFamily: FONT_FAMILY,
    },
    control: {
      fontFamily: FONT_FAMILY,
    },
    content: {
      fontFamily: FONT_FAMILY,
    },
    heading6: {
      fontFamily: FONT_FAMILY,
    },
    heading5: {
      fontFamily: FONT_FAMILY,
    },
    heading4: {
      fontFamily: FONT_FAMILY,
    },
    heading3: {
      fontFamily: FONT_FAMILY,
    },
    heading2: {
      fontFamily: FONT_FAMILY,
    },
    heading1: {
      fontFamily: FONT_FAMILY,
    },
  },
};

export const lateralContainerLss = {
  height: 'fit-content',
  mobile: {
    width: 'initial',
  },
  desktop: { width: '16.438rem' },
};

export const contentContainerLss = {
  mobile: { width: 'initial' },
  desktop: { width: '48.125rem' },
};

export const layoutLss = {
  justifyContent: 'center',
  p: 'xxl',
  gap: 10,
  mobile: { flexDirection: 'column', p: 'md' },
  desktop: { flexDirection: 'row', p: 'xxl' },
};
