import { ButtonLink, ListItem } from '@lucidhq/lucidium';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAuth, usePanelConfig } from '@/hooks';
import { isUserRegistering } from '@/lib';

export function GetSubNavButtonLss(isActive: boolean, panelColorCode: string) {
  return {
    borderRadius: 'none',
    border: 'none',
    color: 'black',
    backgroundColor: 'transparent',
    borderBottom: `0.19rem solid ${isActive ? panelColorCode : 'transparent'}`,
    fontWeight: isActive ? 'bolder' : 'regular',
    ':focus': {
      outline: 'none',
      bg: 'transparent',
      color: 'black',
    },
    ':not([disabled]):hover': {
      bg: 'transparent',
      color: 'black',
      borderBottom: `0.19rem solid ${panelColorCode}`,
    },
  };
}

export function TabGenerator({ title, path }: { title: string; path: string }) {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { panelColorCode, panelGUID } = usePanelConfig();
  const { userRole } = useAuth();
  const userRegistering = isUserRegistering(userRole);
  const active = pathname.includes(path);

  if (userRegistering) return null;

  return (
    <ListItem lss={{ display: 'inline-block' }}>
      <ButtonLink
        to={`/${panelGUID}/${path}${search}`}
        lss={GetSubNavButtonLss(active, panelColorCode)}
        data-testid={`surveys-list-${title}`}
      >
        {t(title)}
      </ButtonLink>
    </ListItem>
  );
}
