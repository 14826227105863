import { Flex, Notification } from '@lucidhq/lucidium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { panelStorage } from '@/lib';

export function AccountClosedNotification() {
  const { t } = useTranslation();
  const [show, setShow] = useState(!!panelStorage.getItem('accountClosed'));

  function handleDismiss() {
    setShow(false);
    panelStorage.removeItem('accountClosed');
  }

  if (!show) return null;

  return (
    <Flex lss={{ marginBottom: '0.625rem' }}>
      <Notification
        palette="success"
        isDismissable
        autoDismisses
        autoDismissSeconds={5}
        onDismiss={handleDismiss}
      >
        {t('Account.Close_Account.Closed_Message')}
      </Notification>
    </Flex>
  );
}
