import { useRef } from 'react';

// This hook provides a ref which is perpetually up to date but will not
// trigger any renders. This is useful for resolving circular references in
// dependency arrays.
export function useNoRenderRef<T>(currentValue: T) {
  const ref = useRef(currentValue);
  ref.current = currentValue;
  return ref;
}
