import {
  ButtonProps,
  Button as LucidiumButton,
  FormSubmitButton as LucidiumFormSubmitButton,
} from '@lucidhq/lucidium';

import { usePanelConfig, useTextButtonColor } from '@/hooks';

const internalLss = {
  borderRadius: '1.5rem',
} as any;

function useBaseStyles(palette: string | undefined) {
  const { panelColorCode } = usePanelConfig();
  const textColor = useTextButtonColor();
  const color = palette === 'secondary' ? 'black' : textColor;
  const backgroundColor =
    palette === 'secondary' ? 'transparent' : panelColorCode;
  const border =
    palette === 'secondary' ? `0.14rem solid ${panelColorCode}` : 'none';

  return {
    ...internalLss,
    backgroundColor,
    border,
    color,
    padding: '0.75rem 1.5rem',
    width: 'initial',
    height: 'initial',
    transition: 'transform 0.75s',
    transform: 'scale(var(--scale, 1))',
    ':hover': {
      color: palette === 'secondary' ? panelColorCode : 'white',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    },
    ':not([disabled]):active': {
      backgroundColor,
      border,
      '--scale': '0.85',
    },
    ':not([disabled]):hover': {
      color: palette === 'secondary' ? panelColorCode : 'white',
      backgroundColor,
      border,
    },
    ':focus': {
      outline: 'none',
    },
    ':focus-visble': {
      outline: 'none',
    },
  };
}

export function Button({
  children,
  lss: propsLss,
  palette,
  ...props
}: ButtonProps) {
  const lss = useBaseStyles(palette);

  return (
    <LucidiumButton
      {...props}
      lss={{
        ...lss,
        ...propsLss,
      }}
    >
      {children}
    </LucidiumButton>
  );
}

export function FormSubmitButton({
  children,
  lss: propsLss,
  palette,
  ...props
}: ButtonProps) {
  const lss = useBaseStyles(palette);

  return (
    <LucidiumFormSubmitButton
      {...props}
      lss={{
        ...lss,
        ...propsLss,
      }}
    >
      {children}
    </LucidiumFormSubmitButton>
  );
}
