export const queryParamKeys = {
  registerSource: 's',
  memberId: 'm',
  referralToken: 'r',
  verificationToken: 'token',
  code: 'code',
  redirectTo: 'redirectto',
  origin: 'origin',
  platformType: 'platformType',
  isIframe: 'isiframe',
};
