import { Loading } from '@lucidhq/lucidium';
import { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { SWRConfig } from 'swr';

import { Layout, RedContainer } from './components';
import { AuthProvider, PanelConfigProvider, useAuth } from './hooks';
import './i18n';
import {
  Analytics,
  isUserPasswordReset,
  isUserPasswordSet,
  isUserRegistering,
  NavigateWithSearchParams,
  SplitProvider,
} from './lib';

const Auth = lazy(() =>
  import('./pages/Auth').then((module) => ({
    default: module.Auth,
  }))
);
const Profile = lazy(() =>
  import('./pages/Profile').then((module) => ({
    default: module.Profile,
  }))
);
const Surveys = lazy(() =>
  import('./pages/Surveys').then((module) => ({
    default: module.Surveys,
  }))
);
const Referral = lazy(() =>
  import('./pages/Referral').then((module) => ({
    default: module.Referral,
  }))
);
const Rewards = lazy(() =>
  import('./pages/Rewards').then((module) => ({
    default: module.Rewards,
  }))
);
const LoginRedirect = lazy(() =>
  import('./pages/LoginRedirect').then((module) => ({
    default: module.LoginRedirect,
  }))
);
const VerifyRegistration = lazy(() =>
  import('./pages/VerifyRegistration').then((module) => ({
    default: module.VerifyRegistration,
  }))
);
const Unsubscribe = lazy(() =>
  import('./pages/Unsubscribe').then((module) => ({
    default: module.Unsubscribe,
  }))
);
const EmailForm = lazy(() =>
  import('./pages/PasswordRecovery/EmailForm').then((module) => ({
    default: module.EmailForm,
  }))
);
const EmailSent = lazy(() =>
  import('./pages/PasswordRecovery/EmailSent').then((module) => ({
    default: module.EmailSent,
  }))
);
const PasswordForm = lazy(() =>
  import('./pages/PasswordRecovery/PasswordForm').then((module) => ({
    default: module.PasswordForm,
  }))
);
const PrivacySettings = lazy(() =>
  import('./pages/PrivacySettings').then((module) => ({
    default: module.PrivacySettings,
  }))
);

const REFRESH_INTERVAL = 1000 * 60 * 5;

const SuspenseWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => <Suspense fallback={<Loading />}>{children}</Suspense>;

function RequireAuth({ children }: { children: JSX.Element }) {
  const { token, userRole } = useAuth();
  const { pathname, search } = useLocation();

  if (!token) {
    return <Navigate to={`./auth${search}`} state={{ from: location }} />;
  }

  if (isUserPasswordReset(userRole) || isUserPasswordSet(userRole)) {
    return <Navigate to={'./password/set'} />;
  }

  if (isUserRegistering(userRole) && !pathname.includes('/profile')) {
    return <Navigate to={`./profile${search}`} />;
  }

  return children;
}

function AlreadyAuthenticated({ children }: { children: JSX.Element }) {
  const { token } = useAuth();

  if (token) {
    return <NavigateWithSearchParams to={`./surveys`} />;
  }

  return children;
}

export function App({
  dedupingInterval = 2000,
}: {
  dedupingInterval?: number;
}) {
  return (
    <SWRConfig
      value={{
        refreshInterval: REFRESH_INTERVAL,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        dedupingInterval,
      }}
    >
      <BrowserRouter>
        <Analytics />
        <SplitProvider>
          <Routes>
            <Route
              path=":panelGUID"
              element={
                <PanelConfigProvider>
                  <AuthProvider>
                    <Outlet />
                  </AuthProvider>
                </PanelConfigProvider>
              }
            >
              <Route
                index
                element={<NavigateWithSearchParams to={`./surveys`} />}
              />
              <Route
                element={
                  <RequireAuth>
                    <Layout>
                      <SuspenseWrapper>
                        <Outlet />
                      </SuspenseWrapper>
                    </Layout>
                  </RequireAuth>
                }
              >
                <Route path="surveys" element={<Surveys />} />
                <Route path="rewards/*" element={<Rewards />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="referral" element={<Referral />} />
                <Route path="privacysettings" element={<PrivacySettings />} />
              </Route>
              <Route
                path="auth"
                element={
                  <SuspenseWrapper>
                    <Outlet />
                  </SuspenseWrapper>
                }
              >
                <Route
                  index
                  element={<NavigateWithSearchParams to={`signIn`} />}
                />
                <Route
                  path="signIn"
                  element={
                    <AlreadyAuthenticated>
                      <Auth />
                    </AlreadyAuthenticated>
                  }
                />
                <Route
                  path="signUp"
                  element={
                    <AlreadyAuthenticated>
                      <Auth />
                    </AlreadyAuthenticated>
                  }
                />
                <Route path="verify" element={<VerifyRegistration />} />
                <Route path="redirect" element={<LoginRedirect />} />
                <Route path="unsubscribe" element={<Unsubscribe />} />
              </Route>
              <Route
                path="password"
                element={
                  <RedContainer>
                    <SuspenseWrapper>
                      <Outlet />
                    </SuspenseWrapper>
                  </RedContainer>
                }
              >
                <Route
                  index
                  element={<NavigateWithSearchParams to={`forgot`} />}
                />
                <Route path="set" element={<PasswordForm />} />
                <Route path="forgot">
                  <Route
                    index
                    element={<NavigateWithSearchParams to={`email`} />}
                  />
                  <Route path="email" element={<EmailForm />} />
                  <Route path="emailsent" element={<EmailSent />} />
                  <Route path="reset" element={<PasswordForm />} />
                </Route>
              </Route>
              {/* Old/Embed Routes */}
              <Route
                path="register"
                element={<NavigateWithSearchParams to={`../auth/signUp`} />}
              />
              <Route
                path="loginRedirect"
                element={<NavigateWithSearchParams to={`../auth/redirect`} />}
              />
              <Route
                path="verifyEmail"
                element={<NavigateWithSearchParams to={`../auth/verify`} />}
              />
              <Route path="public">
                <Route
                  index
                  element={<NavigateWithSearchParams to={`../auth/verify`} />}
                />
                <Route
                  path="verify"
                  element={
                    <NavigateWithSearchParams to={`../../auth/verify`} />
                  }
                />
                <Route
                  path="unsubscribe"
                  element={
                    <NavigateWithSearchParams to={`../../auth/unsubscribe`} />
                  }
                />
              </Route>
              <Route
                path="reward/inApp"
                element={<NavigateWithSearchParams to={`../rewards`} />}
              />
              <Route
                path="profiling/inApp"
                element={<NavigateWithSearchParams to={`../profile`} />}
              />
              <Route
                path="*"
                element={<NavigateWithSearchParams to={`./`} />}
              />
            </Route>
          </Routes>
        </SplitProvider>
      </BrowserRouter>
    </SWRConfig>
  );
}
