import { useIsIframe, usePanelConfig } from '@/hooks';

export function Logo() {
  const { panelLogoUrl, panelName } = usePanelConfig();
  const isIframe = useIsIframe();

  if (isIframe) return null;

  return (
    <img
      src={panelLogoUrl}
      alt={panelName}
      style={{ height: 'auto', maxWidth: '18.75rem' }}
    />
  );
}
