import { usePanelConfig } from '@/hooks';
import { DropdownMenuItem, Link } from '@lucidhq/lucidium';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

const linkStyles = {
  textDecoration: 'none',
  border: 'none',
  px: 'xs',
  py: 'sm',
  ':not([disabled]):hover': {
    textDecoration: 'none',
    bg: 'gray300',
  },
} as any;

export function MenuOptionGenerator({
  title,
  path,
  onClick,
}: {
  title: string;
  path: string;
  onClick: () => void;
}) {
  const { panelGUID } = usePanelConfig();
  const { search } = useLocation();

  return (
    <Link
      onClick={onClick}
      key={title}
      to={`/${panelGUID}/${path}${search}`}
      lss={linkStyles}
    >
      <DropdownMenuItem
        text={t(title)}
        textLss={{
          ':hover': {
            textDecoration: 'none',
          },
        }}
      />
    </Link>
  );
}
