import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { panelStorage } from '@/lib/panelStorage';

export function useLocalStorage<S>(
  key: string,
  initialValue: S
): [S, (value: S) => void] {
  const { panelGUID } = useParams();
  const finalKey = `${panelGUID}-${key}`.toUpperCase();

  const [storedValue, setStoredValue] = useState<S>(() => {
    try {
      const item = panelStorage.getItem(finalKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  function setValue(value: S) {
    try {
      setStoredValue(value);
      panelStorage.setItem(finalKey, JSON.stringify(value));
    } catch (error) {}
  }

  return [storedValue, setValue];
}
