import {
  CheckboxGroup,
  Dialog,
  Field,
  Flex,
  Form,
  FormErrorBanner,
  H5,
  Text,
} from '@lucidhq/lucidium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DomPurified, FormSubmitButton } from '@/components/Shared';
import { useAuth, useSWRMutationAuth } from '@/hooks';
import { endpoints } from '@/lib';
import { ConsentOptIn } from '@/lib/models';

import { DoBField, GenderField, PostalCodeField } from '../Fields';
import { usePartialUpdateModal } from './hooks';

const Today = new Date();
const NotToday = new Date();
NotToday.setFullYear(Today.getFullYear() - 18);

export function PartialUpdateModal() {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(true);
  const {
    basicProfile,
    isMinor,
    consentsFiltered,
    needsUpdate,
    updateConsents,
    updateProfile,
  } = usePartialUpdateModal();
  const { token, logout, refreshToken } = useAuth();
  const { trigger: updateProfileTrigger, isMutating: isMutatingProfile } =
    useSWRMutationAuth<[]>({
      url: endpoints.profilePartialUpdate,
      errorMessage: 'unable to request update profile',
    });
  const { trigger: updateConsentsTrigger, isMutating: isMutatingConsents } =
    useSWRMutationAuth({
      url: endpoints.createPanelistConsents,
      errorMessage: 'unable to request update consents',
    });
  const { trigger: signOutTrigger } = useSWRMutationAuth({
    url: endpoints.logout,
  });
  const formInitValues = {
    dob: NotToday.toISOString().split('T')[0],
    postalCode: '',
    gender: '',
    tos: [] as string[],
  };
  const isLoading = isMutatingProfile || isMutatingConsents;

  if (isMinor) return null;

  if (!basicProfile || !consentsFiltered) return null;

  if (Object.values(needsUpdate).every((v) => !v)) return null;

  async function onSubmit(
    { dob, gender, postalCode, tos }: typeof formInitValues,
    formActions: any
  ) {
    try {
      const profileUpdates = [];
      if (needsUpdate.birthDate)
        profileUpdates.push({ dimensionName: 'DateOfBirth', value: dob });
      if (needsUpdate.gender)
        profileUpdates.push({ dimensionName: 'Gender', value: gender });
      if (needsUpdate.postalCode)
        profileUpdates.push({ dimensionName: 'PostalCode', value: postalCode });

      if (needsUpdate.consents && consentsFiltered) {
        const consentUpdates: ConsentOptIn[] = consentsFiltered
          ?.filter((consent) => tos.includes(consent.consentId.toString()))
          .map((o) => ({
            consentId: o.consentId,
            consentContentId: o.consentContentId,
            optIn: true,
          }));

        if (consentUpdates.length > 0) {
          await updateConsentsTrigger(consentUpdates);
          updateConsents(consentUpdates);
        }
      }

      if (profileUpdates && profileUpdates.length > 0) {
        const profileUpdateResponse =
          await updateProfileTrigger(profileUpdates);
        if (profileUpdateResponse && profileUpdateResponse.length > 0) {
          formActions.setStatus({
            error: profileUpdateResponse
              .map((e: any) => t(e.message))
              .join(' '),
          });
        } else {
          updateProfile(profileUpdates);
        }
      }

      setShowDialog(false);
    } catch (error: any) {
      formActions.setStatus({ error: t('Errors.Unexpected_Error') });
    }
  }

  return (
    <Dialog closeOnEscPress isOpen={showDialog} lss={{ padding: '0.625rem' }}>
      <Flex
        lss={{
          justifyContent: 'end',
        }}
      >
        <Button
          type="button"
          onClick={() => setShowDialog(false)}
          emphasis="low"
          lss={{
            bg: 'transparent',
            border: 'none',
            borderRadius: 'none',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={18}
            height={18}
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </Button>
      </Flex>
      <Flex
        lss={{
          flexDirection: 'column',
          marginBottom: '0.938rem',
          gap: '0.625rem',
        }}
      >
        <H5>{t('Account.Incomplete_Basic_Profile.Title')}</H5>
        <Text>{t('Account.Incomplete_Basic_Profile.Detail')}</Text>
      </Flex>
      <Form initialValues={formInitValues} onSubmit={onSubmit}>
        {needsUpdate.gender && (
          <GenderField data-testid="partial-update-modal-form-gender" />
        )}
        {needsUpdate.birthDate && (
          <DoBField data-testid="partial-update-modal-form-dob" />
        )}
        {needsUpdate.postalCode && (
          <PostalCodeField data-testid="partial-update-modal-form-postal-code" />
        )}
        {needsUpdate.consents && (
          <Field<string[]>
            name="tos"
            data-testid="partial-update-modal-form-tos"
            render={(fieldProps) => (
              <CheckboxGroup
                {...fieldProps}
                options={consentsFiltered.map((consent) => ({
                  label: (
                    <Flex>
                      <DomPurified html={consent.termsOfUse} />
                      <DomPurified html={consent.description} />
                    </Flex>
                  ) as unknown as string,
                  value: consent.consentId.toString(),
                }))}
              />
            )}
          />
        )}
        <FormErrorBanner lss={{ my: 'md' }} />
        <Flex lss={{ flexDirection: 'row', gap: '0.475rem' }}>
          <FormSubmitButton
            disabled={isLoading}
            palette="primary"
            scale="sm"
            lss={{
              width: '9.375rem',
            }}
            data-testid="partial-update-modal-form-submit"
          >
            {t('Forms.Update')}
          </FormSubmitButton>
          <Button
            palette="secondary"
            scale="sm"
            data-testid={`partial-update-modal-form-logout`}
            onClick={async () => {
              try {
                await signOutTrigger({ token, refreshToken });
                logout();
              } catch (error) {}
            }}
          >
            {t('Auth.Log_out')}
          </Button>
        </Flex>
      </Form>
    </Dialog>
  );
}
