import { Loading, LucidiumProvider, ThemeInterface } from '@lucidhq/lucidium';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { ErrorPage } from '@/components/ErrorPage';
import { baseFetch, defaultThemeProps, endpoints } from '@/lib';
import { PanelConfig } from '@/lib/models';

const isNotTest = import.meta.env.MODE !== 'test';

const initialValues = {
  panelGUID: '',
  panelName: '',
  panelId: 0,
  panelLogoUrl: '',
  twoLetterIsoCountryCode: '',
  panelLanguageCode: '',
  panelColorCode: '',
  profilingSettings: {
    showPostalCode: false,
    showDaysBetweenMailOuts: false,
    isMobilePhoneFieldEnabled: false,
    isMobilePhoneFieldRequired: false,
    postalCodeRegex: '',
  },
  isRegistrationEnabled: true,
  showRewards: false,
  showReferral: false,
  minAgeRequirement: 0,
  isCaptchaEnabled: false,
  isLoginEnabled: true,
};

const PanelConfigContext = createContext<PanelConfig>(initialValues);
const Provider = PanelConfigContext.Provider;

export function usePanelConfig() {
  return useContext(PanelConfigContext);
}

// Gets the config from the API and sets the context
// Also sets the language of the app
export function PanelConfigProvider({ children }: { children: ReactNode }) {
  const panelGUID = useParams().panelGUID || '';
  const isValidGUID = panelGUID.length === 36;
  const {
    data: panelConfig,
    isLoading,
    error,
  } = useSWR<PanelConfig>(endpoints.getPanelConfig(panelGUID), baseFetch);
  const { i18n } = useTranslation();
  const [theme, setTheme] = useState<ThemeInterface | undefined>({
    ...defaultThemeProps,
    template: 'LUCID',
  } as ThemeInterface);
  const [loadingLanguage, setLoadingLanguage] = useState(isNotTest);
  const loading = isLoading || loadingLanguage;
  const panelConfigJson = JSON.stringify(panelConfig);

  useEffect(() => {
    async function setDefaultSettings() {
      if (!panelConfig) return;

      if (panelConfig.panelLanguageCode)
        setTheme({
          ...theme,
          colors: {
            primary: panelConfig.panelColorCode,
          },
        } as ThemeInterface);

      if (panelConfig.panelLanguageCode)
        await i18n.changeLanguage(panelConfig.panelLanguageCode || 'en', () =>
          setLoadingLanguage(false)
        );
    }

    setDefaultSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelConfigJson]);

  useEffect(() => {
    if (error) setLoadingLanguage(false);
  }, [error]);

  return (
    <Provider
      value={{
        ...(panelConfig || initialValues),
        panelGUID,
      }}
    >
      <LucidiumProvider theme={theme}>
        <>
          {loading && <Loading />}
          {!loading && !error && isValidGUID && children}
          {!loading && (!isValidGUID || error) && (
            <ErrorPage message="NewMessages.NotFoundPanel" />
          )}
        </>
      </LucidiumProvider>
    </Provider>
  );
}
