import { useBreakpoint } from '@lucidhq/lucidium';
import { lazy, Suspense } from 'react';

const ChildFriendlyIcon = lazy(
  () => import('./CategoryIcons/ChildFriendlyTwoTone')
);
const CottageIcon = lazy(() => import('./CategoryIcons/CottageTwoTone'));
const FlatwareIcon = lazy(() => import('./CategoryIcons/FlatwareTwoTone'));
const FlightTakeoffIcon = lazy(
  () => import('./CategoryIcons/FlightTakeoffTwoTone')
);
const LocalHospitalIcon = lazy(
  () => import('./CategoryIcons/LocalHospitalTwoTone')
);
const MopedIcon = lazy(() => import('./CategoryIcons/MopedTwoTone'));
const PermIdentityIcon = lazy(
  () => import('./CategoryIcons/PermIdentityTwoTone')
);
const PianoIcon = lazy(() => import('./CategoryIcons/PianoTwoTone'));
const PictureInPictureIcon = lazy(
  () => import('./CategoryIcons/PictureInPictureTwoTone')
);
const PowerOffIcon = lazy(() => import('./CategoryIcons/PowerOffTwoTone'));
const RadarIcon = lazy(() => import('./CategoryIcons/RadarTwoTone'));
const RoomIcon = lazy(() => import('./CategoryIcons/RoomTwoTone'));
const SchoolIcon = lazy(() => import('./CategoryIcons/SchoolTwoTone'));
const SportsEsportsIcon = lazy(
  () => import('./CategoryIcons/SportsEsportsTwoTone')
);
const ThumbUpAltIcon = lazy(() => import('./CategoryIcons/ThumbUpAltTwoTone'));
const WhatshotIcon = lazy(() => import('./CategoryIcons/WhatshotTwoTone'));
const WorkIcon = lazy(() => import('./CategoryIcons/WorkTwoTone'));

export type CategoryItemIconType = keyof typeof iconComponents;

const iconComponents = {
  'fa-user': PermIdentityIcon,
  'fa-home': CottageIcon,
  'fa-graduation-cap': SchoolIcon,
  'fa-briefcase': WorkIcon,
  'fa-car': MopedIcon,
  'fa-cutlery': FlatwareIcon,
  'fa-thumbs-up': ThumbUpAltIcon,
  'fa-power-off': PowerOffIcon,
  'fa-gamepad': SportsEsportsIcon,
  'fa-music': PianoIcon,
  'fa-plane': FlightTakeoffIcon,
  'fa-fire': WhatshotIcon,
  'fa-hospital-o': LocalHospitalIcon,
  'fa-binoculars': RadarIcon,
  'fa-child': ChildFriendlyIcon,
  'fa-map-marker': RoomIcon,
};

export function GetIcon(key: CategoryItemIconType) {
  const isMobile = !useBreakpoint('600px');
  const iconSize = isMobile ? 52.5 : 70;
  const Icon = iconComponents[key] || PictureInPictureIcon;

  return <Suspense>{<Icon size={iconSize} />}</Suspense>;
}
