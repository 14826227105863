import { Flex, H1, H2 } from '@lucidhq/lucidium';
import { useTranslation } from 'react-i18next';

export function ErrorPage({ message }: { message: string }) {
  const { t } = useTranslation();

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        gap: '0.625rem',
        padding: '50px',
        backgroundColor: '#1e0024',
        height: '100vh',
      }}
    >
      <H1 lss={{ color: 'white' }}>404</H1>
      <H2 lss={{ color: 'white' }}>{t(message)}</H2>
    </Flex>
  );
}
