import { ButtonLink, ListItem } from '@lucidhq/lucidium';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

import { useAuth, usePanelConfig } from '@/hooks';
import { isUserValid } from '@/lib';

import { GetSubNavButtonLss } from './TabGenerator';

export function TabReferral() {
  const { userRole } = useAuth();
  const { panelGUID, panelColorCode, showReferral } = usePanelConfig();
  const { pathname, search } = useLocation();
  const path = `/${panelGUID}/referral`;
  const active = pathname.includes(path);

  if (!showReferral || !isUserValid(userRole)) return null;

  return (
    <ListItem lss={{ display: 'inline-block' }}>
      <ButtonLink
        to={`${path}${search}`}
        lss={GetSubNavButtonLss(active, panelColorCode)}
        data-testid={`surveys-list-Referral.Title`}
      >
        {t('Referral.Title')}
      </ButtonLink>
    </ListItem>
  );
}
