import { Flex, NavLink } from '@lucidhq/lucidium';
import { Fragment } from 'react';

import { usePanelConfig, useSWRAuth } from '@/hooks';
import { endpoints } from '@/lib';

interface FooterLinks {
  termsAndConditions: Link;
  privacyPolicy: Link;
  faq: Link;
  helpdeskLink: Link;
  countrySpecificPolicy?: any;
}

interface Link {
  link: string;
  text: string;
  linkWithText: string;
}

const currentYear = new Date().getFullYear();

function Links(data: FooterLinks | undefined, panelColorCode: string) {
  const links = Object.entries(data || []).filter(
    ([key, value]) => key !== 'helpdeskLink' && value?.link
  );

  return links.map(([key, value], index) => (
    <Fragment key={key}>
      <NavLink
        target="_blank"
        to={value.link}
        lss={{ textDecoration: 'none', color: panelColorCode as any }}
      >
        {value.text}
      </NavLink>
      {index < links.length - 1 && ' | '}
    </Fragment>
  ));
}

export function Footer() {
  const { panelGUID, panelColorCode } = usePanelConfig();
  const { data, isLoading, error } = useSWRAuth<FooterLinks>({
    url: panelGUID ? endpoints.getLanguageLinks(panelGUID) : undefined,
    errorMessage: 'unable to request footer links',
  });

  return (
    <>
      {!isLoading && !error && (
        <Flex
          lss={{
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 10,
            alignItems: 'center',
            paddingBottom: '2rem',
            flexWrap: 'wrap',
          }}
        >
          <Flex
            lss={{
              flexDirection: 'row',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {`All content 2004-${currentYear} - Cint AB, Stockholm Sweden`}
          </Flex>
          <Flex
            lss={{
              flexDirection: 'row',
              gap: 10,
              flexWrap: 'wrap',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {Links(data, panelColorCode)}
          </Flex>
        </Flex>
      )}
    </>
  );
}
