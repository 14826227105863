import { Role, Roles } from './models';

export function isUserRegistering(userRole: Role) {
  return userRole === Roles.Registering;
}

export function isUserMinor(userRole: Role) {
  return userRole === Roles.Minor;
}

export function isUserValid(userRole: Role) {
  return userRole === Roles.User;
}

export function isUserPasswordReset(userRole: Role) {
  return userRole === Roles.PasswordReset;
}

export function isUserPasswordSet(userRole: Role) {
  return userRole === Roles.PasswordSet;
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export * from './validators';
export * from './Analytics';
export * from './useNoRenderRef';
export * from './splitIO';
export * from './endpoints';
export * from './env';
export * from './queryParamKeys';
export * from './styles';
export * from './formatters';
export * from './requestHelpers';
export * from './events';
export * from './featureFlags';
export * from './color';
export * from './panelStorage';
export * from './Navigators';
