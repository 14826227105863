import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

import { useAuth } from '@/hooks/useAuth';

import { SPLIT_IO_BROWSER_API_KEY } from './env';

export function SplitProvider({ children }: { children: any }) {
  const { panelistId } = useAuth();
  const splitioConfig = {
    core: {
      authorizationKey: SPLIT_IO_BROWSER_API_KEY,
      key: panelistId || 'anonymous',
      trafficType: panelistId ? 'user' : 'anonymous',
    },
  };

  return <SplitFactory config={splitioConfig}>{children}</SplitFactory>;
}

export function FeatureFlag({
  featureName,
  when,
  children,
  attributes,
}: {
  featureName: string;
  when: string;
  children: any;
  attributes?: SplitIO.Attributes;
}) {
  function renderContent({ treatments, isReady }: ISplitTreatmentsChildProps) {
    const { treatment } = treatments[featureName];
    if (treatment !== when || !isReady) return null;
    return children;
  }

  return (
    <SplitTreatments names={[featureName]} attributes={attributes}>
      {renderContent}
    </SplitTreatments>
  );
}
