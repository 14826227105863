export class Color {
  public static FromHex(hex: string): Color {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return new Color(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      );
    }

    // Default value
    return this.FromHex('#9975b9');
  }

  constructor(
    private _r: number,
    private _g: number,
    private _b: number
  ) {}

  public get r(): number {
    return this._r;
  }

  public get g(): number {
    return this._g;
  }

  public get b(): number {
    return this._b;
  }

  /**
   * Returns the hex string of the color e.g. "#ff69b4"
   */
  public get hexValue(): string {
    return `#${this.componentToHex(this.r)}${this.componentToHex(
      this.g
    )}${this.componentToHex(this.b)}`;
  }

  /**
   * https://www.w3.org/TR/AERT#color-contrast
   */
  public perceivedBrightness(): number {
    return (this.r * 299 + this.g * 587 + this.b * 114) / 1000;
  }

  public isBrightColor(brightnessThreshold: number): boolean {
    return this.perceivedBrightness() > brightnessThreshold;
  }

  /**
   * Finds a color pair for use in gradients etc
   * For colors brighter than some threshold, find a darker color as other color
   * For colors darker than some threshold, find a lighter color as other color
   * Return pair in decreasing order of brightness
   * @param factor The difference between the two colors
   * @param brightnessThreshold The perceivedBrightness threshold for whether to choose a darker/lighter color
   */
  public produceComplimentaryColorPair(
    factorS: number,
    factorL: number,
    brightnessThreshold: number
  ): [Color, Color] {
    if (this.perceivedBrightness() > brightnessThreshold) {
      return [this, this.applyFactor(-factorS, -factorL)]; // darker
    }
    return [this.applyFactor(factorS, factorL), this]; // brighter
  }

  /**
   * Makes things darker
   * Returns a new shade of the original color based on the factor parameter between 0 and 1.
   * 0 gives no change and 1 gives maximum amount darker.
   */
  public shade(factor: number): Color {
    return new Color(
      Math.floor(this.r * (1 - factor)),
      Math.floor(this.g * (1 - factor)),
      Math.floor(this.b * (1 - factor))
    );
  }

  /**
   * Makes things Lighter
   * Returns a new tint of the original color based on the factor parameter between 0 and 1.
   * 0 gives no change and 1 gives maximum amount lighter.
   */
  public tint(factor: number): Color {
    return new Color(
      Math.floor(this.r + (255 - this.r) * factor),
      Math.floor(this.g + (255 - this.g) * factor),
      Math.floor(this.b + (255 - this.b) * factor)
    );
  }

  private applyFactor(factorS: number, factorL: number): Color {
    const hslColor = this.toHsl();
    return this.hslToRgb(
      hslColor[0],
      hslColor[1] + factorS,
      hslColor[2] + factorL
    );
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  private toHsl(): [number, number, number] {
    const r = this._r / 255;
    const g = this._g / 255;
    const b = this._b / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    const l = (max + min) / 2;
    let h, s;

    if (max === min) {
      h = s = 0;
    } else {
      const diff = max - min;
      s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
      switch (max) {
        case r:
          h = (g - b) / diff + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / diff + 2;
          break;
        case b:
          h = (r - g) / diff + 4;
          break;
      }
      // eslint-disable-next-line
      // @ts-ignore-next-line
      h = h / 6;
    }
    return [h, s, l];
  }

  private hueToRgb(p: number, q: number, t: number): number {
    if (t < 0) {
      t += 1;
    }
    if (t > 1) {
      t -= 1;
    }
    if (t < 1 / 6) {
      return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
      return q;
    }
    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
  }

  private hslToRgb(h: number, s: number, l: number): Color {
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = this.hueToRgb(p, q, h + 1 / 3);
      g = this.hueToRgb(p, q, h);
      b = this.hueToRgb(p, q, h - 1 / 3);
    }
    return new Color(
      Math.round(r * 255),
      Math.round(g * 255),
      Math.round(b * 255)
    );
  }
}
