import { trackError } from '@lucidhq/ui-analytics';
import useSWRMutation from 'swr/mutation';

import { request, timeout } from '@/lib';

import { useAuth } from './useAuth';

const WAIT_RETRY_REQUEST = 1000;

export function useSWRMutationAuth<T>({
  url,
  method = 'POST',
  isFormData = false,
  urlToMutate,
  errorMessage,
  config,
  is400 = true,
}: {
  url: string;
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  isFormData?: boolean;
  urlToMutate?: string;
  errorMessage?: string;
  config?: any;
  is400?: boolean;
}) {
  const { token, userName, loading: loadingAuth } = useAuth();

  function onError(error: any, key: string) {
    trackError(errorMessage || key, error);
  }

  const {
    isMutating,
    data,
    error,
    trigger: internalTrigger,
    reset,
  } = useSWRMutation<T>(
    urlToMutate || url,
    (_: string, { arg }: any) =>
      request({ url, method, body: arg, token, userName, isFormData, is400 }),
    {
      ...config,
      onError,
    }
  );

  async function trigger(body?: any, ...args: any) {
    loadingAuth && (await timeout(WAIT_RETRY_REQUEST));
    return internalTrigger(body, args);
  }

  return {
    isMutating,
    data,
    error,
    trigger,
    reset,
  };
}
