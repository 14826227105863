import { Field } from '@lucidhq/lucidium';
import { useTranslation } from 'react-i18next';

import { usePanelConfig } from '@/hooks';

export function PostalCodeField() {
  const { t } = useTranslation();
  const { profilingSettings } = usePanelConfig();
  const { postalCodeRegex } = profilingSettings;

  return (
    <Field
      label={t('Panelist.Postal_Code')}
      name="postalCode"
      type="text"
      required
      maxLength={20}
      validate={(value): any => !new RegExp(postalCodeRegex).test(value)}
    />
  );
}
