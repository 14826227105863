import { Flex, useBreakpoint, useTheme } from '@lucidhq/lucidium';

import { useIsIframe } from '@/hooks';

import { Footer } from '../Layout/Footer';
import { Logo } from '../Logo';
import { AccountClosedNotification } from './';

export function RedContainer({
  children,
  header = false,
}: {
  children: React.ReactNode;
  header?: boolean;
}) {
  const theme = useTheme();
  const isMobile = !useBreakpoint('600px');
  const isIframe = useIsIframe();

  return (
    <>
      <Flex
        lss={{
          flexDirection: 'column',
          justifyContent: 'center',
          m: 'xxxl',
          alignItems: 'center',
        }}
      >
        <Logo />
        {header && <AccountClosedNotification />}
        <Flex
          lss={{
            background: theme.colors.white,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)' as any,
            borderRadius: '5px' as any,
            flexDirection: 'column',
            width: isMobile ? '125%' : '31.25rem',
            marginBottom: '1.25rem',
            marginTop: !isIframe ? '1.875rem' : '0',
            p: !header ? 'xxl' : 0,
          }}
        >
          {children}
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
